import React from 'react';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import Title from '../components/Title';
import Form from '../components/Form';

// import Image1 from '../../assets/images/unsplash/markus-spiske-445251-unsplash.jpg';
import SamplePackImage from '../../assets/images/sample-pack.jpg';

const schema = Yup.object().shape({
  company: Yup.string().required('Company Name is required'),
  contact: Yup.string().required('Contact is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  county: Yup.string(),
  postcode: Yup.string().required('Postcode is required'),
  phone: Yup.string().required('Phone is required')
});

const initialValues = {
  company: '',
  contact: '',
  email: '',
  address: '',
  city: '',
  county: '',
  postcode: '',
  phone: ''
};

const fields = [
  { name: 'company', label: 'Company Name', type: 'input', required: true },
  { name: 'contact', label: 'Contact', type: 'input', required: true },
  { name: 'email', label: 'Email', type: 'input', required: true },
  { name: 'address', label: 'Address', type: 'textarea', required: true },
  { name: 'city', label: 'Town/City', type: 'input', required: true },
  { name: 'county', label: 'County', type: 'input' },
  { name: 'postcode', label: 'Postcode', type: 'input', required: true },
  { name: 'phone', label: 'Phone', type: 'input', required: true }
];

const Sample = () => (
  <Layout title="Request a Sample">
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title
          main="Sample Pack Request Form"
          helper="Our sample pack contains a wide range of products that are supplied by the Calf Hey Group. From
                    plain labels to window stickers, 'Split-lam' labels and domed labels, all our products are produced
                    to the highest quality at very competitive <strong>TRADE ONLY</strong> prices. <br /><br />
                    PLEASE NOTE: Sample packs are only available to the <strong>TRADE. </strong>
                    i.e. printers and allied industries. <br /><br />
                    <strong>*</strong> Indicates a required field"
        />
        <div className="c-content-feedback-1 c-option-2">
          <div className="row">
            <div className="col-md-offset-2 col-md-8">
              <div className="c-container c-bg-white c-border-radius">
                <div className="c-content-title-1">
                  <h2 className="c-font-uppercase c-font-bold c-font-dark c-font-center c-font-30">
                    Get a Free Sample
                  </h2>
                  <div className="c-line-center c-theme-bg" />

                  <Form
                    subject="New Sample Pack Request at Calf Hey"
                    schema={schema}
                    fields={fields}
                    initialValues={initialValues}
                  />
                  <div className="c-center c-margin-t-60 c-margin-b-40">
                    <img src={SamplePackImage} alt="brochure-and-sample-pack" width="80%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Sample;
